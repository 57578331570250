import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import Features from "components/features/VerticalWithAlternateImageAndText.js";
import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";

import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import Blog from "components/blogs/GridWithFeaturedPost.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStartedLight.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";

const HighlightedText = tw.span`text-indigo-500`;

export default () => {
  return (
    <AnimationRevealPage>
      <Hero
        heading="Serving the Textile Industry since 1995"
        description="Eurasia Temac Pvt Ltd was established in the year 1995, in Mumbai, Maharshtra and subsequently a workshop at MIDC Gokulshirgaon at Kolhapur"
        features={[]}
        primaryButtonText="Download Brochure"
        primaryButtonUrl="/Brochure TMH.pdf"
        imageSrc="/hero.png"
      />
      <FeatureStats
        heading="Company history and associations"
        description="In the past we were associated with leading companies , such as Amsler AG, Switzerland, m/s Graf +Cie AG, Switzerland, M/s
Zweigle GmbH, Germany, M/s Genkinger GmbH, Germany and M/s Fischer-Poege GmbH, Germany."
        stats={[
          {
            key: "Currently we are an independent manufacturing company providing customised material handling solutions.",
            value: "",
          },
        ]}
      />
      <Features
        heading={
          <>
            Amazing <HighlightedText>Features</HighlightedText>
          </>
        }
      />
      <ContactUsForm />
      <Footer />
    </AnimationRevealPage>
  );
};
